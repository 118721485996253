import { Card, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

import grasroten from "../../img/grasroten.jpeg";
import inab from "../../img/inab.png";
import peab from "../../img/peab.png";
import obos from "../../img/obos.png";
import brootak from "../../img/brootak.png";
import umia from "../../img/umia-1.png";
import selbergs from "../../img/selbergs-1.png";
import assemblin from "../../img/assemblin-1.png";

export default function Sponsors() {
  const cardStyle = {
    m: "10px",
    width: "100%",
    height: "112px",
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "60vh" }}
    >
      <Typography
        variant="h3"
        sx={{
          fontFamily: "BlinkMacSystemFont",
          color: "text.secondary",
          fontWeight: 600,
        }}
      >
        Sponsorer
      </Typography>

      <Paper elevation={0} sx={{ textAlign: "left", p: "10px" }}>
        <Stack sx={{ flexDirection: { xs: "row", sm: "row", md: "column" } }}>
          <Stack
            justifyContent={"center"}
            m="10px"
            sx={{ flexDirection: { sm: "column", md: "row" } }}
          >
            <Card elevation={0} sx={cardStyle}>
              <img height={"75%"} width={"75%"} src={brootak} alt="brootak" />
            </Card>
            <Card elevation={0} sx={cardStyle}>
              <img height={"75%"} width={"75%"} src={obos} alt="obos" />
            </Card>
            <Card elevation={0} sx={cardStyle}>
              <img height={"75%"} width={"75%"} src={peab} alt="peab" />
            </Card>
          </Stack>
          <Stack
            justifyContent={"center"}
            m="10px"
            sx={{ flexDirection: { sm: "column", md: "row" } }}
          >
            <Card elevation={0} sx={cardStyle}>
              <img height={"100%"} width={"100%"} src={inab} alt="inab" />
            </Card>
            <Card elevation={0} sx={cardStyle}>
              <img
                height={"100%"}
                width={"100%"}
                src={grasroten}
                alt="grasroten"
              />
            </Card>
          </Stack>
          <Stack
            justifyContent={"center"}
            m="10px"
            sx={{ flexDirection: { sm: "column", md: "row" } }}
          >
            <Card elevation={0} sx={cardStyle}>
              <img height={"100%"} width={"100%"} src={umia} alt="umia" />
            </Card>
            <Card elevation={0} sx={cardStyle}>
              <img
                height={"100%"}
                width={"100%"}
                src={assemblin}
                alt="assemblin"
              />
            </Card>
            <Card elevation={0} sx={cardStyle}>
              <img
                height={"100%"}
                width={"100%"}
                src={selbergs}
                alt="selbergs"
              />
            </Card>
          </Stack>
        </Stack>
      </Paper>

      {/* <Card
          sx={{
            mt: "10px",
            borderRadius: "16px",
            width: "80%",
            maxHeight: "700px",
          }}
        >
          <SponsorCarousel></SponsorCarousel>
        </Card> */}
      {/* </Stack> */}
    </Grid>
  );
}
