import * as React from "react";
import background from "../img/abst.jpeg";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MapIcon from "@mui/icons-material/Map";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EmailIcon from "@mui/icons-material/Email";

export default function ContactUs() {
  const styles = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    minHeight: "100%",
    height: "40vh",
    width: "100%",
    maxWidth: "100%",
  };

  const boxStyle = {
    display: "flex",
    "& > :not(style)": {
      m: 1,
      height: "30vh",
      width: {
        xs: "100%",
        sm: "500px",
      },
    },
    flexDirection: { xs: "column", sm: "column", md: "row" },
  };

  return (
    <body>
      <div style={styles}></div>
      <Typography
        sx={{
          fontSize: { xs: "24px", sm: "45px", md: "60px" },
          fontFamily: "Georgia",
        }}
      >
        Kontaktinformation
      </Typography>
      
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Typography
        sx={{
          width:"80%",
          fontSize: { xs: "16px", sm: "16px", md: "16px" },
          fontFamily: "Georgia",
        }}
      >
        Vi uppmanar till att kontakta oss i förstahand genom e-post, men självklart går de andra kontaktvägarna bra även vid behov.
        We urge you to contact us first and foremost via e-mail, but the other contact points are of course viable when needed.
      </Typography>
        <Stack direction={"column"}>
          <Box sx={boxStyle}>
          <Paper elevation={3}>
              <EmailIcon sx={{ fontSize: 100 }}></EmailIcon>
              <Typography variant="h3">E-post</Typography>
              <Typography variant="h5"> info@umecurling.se</Typography>
            </Paper>
            <Paper elevation={3}>
              <SmartphoneIcon sx={{ fontSize: 100 }}></SmartphoneIcon>
              <Typography variant="h3">Swish</Typography>
              <Typography variant="h5">1231611987</Typography>
            </Paper>
          </Box>
          <Box sx={boxStyle}>
            <Paper elevation={3}>
              <MapIcon sx={{ fontSize: 100 }}></MapIcon>
              <Typography variant="h3">Adress</Typography>
              <Typography variant="h5"> Signalvägen 11 90322 Umeå</Typography>
            </Paper>
            <Paper elevation={3}>
              <AccountBalanceIcon sx={{ fontSize: 100 }}></AccountBalanceIcon>
              <Typography variant="h3">PlusGiro</Typography>
              <Typography variant="h5">205511-9</Typography>
            </Paper>
          </Box>
          <Box sx={boxStyle}>
            <Paper elevation={3}>
              <CorporateFareIcon sx={{ fontSize: 100 }}></CorporateFareIcon>
              <Typography variant="h3">Organisationsnummer</Typography>
              <Typography variant="h5"> 894001-3751</Typography>
            </Paper>
            <Paper elevation={3}>
              <PhoneEnabledIcon sx={{ fontSize: 100 }}></PhoneEnabledIcon>
              <Typography variant="h3">Telefonnummer</Typography>
              <Typography variant="h5"> 0705402884</Typography>
            </Paper>
          </Box>
        </Stack>
      </Grid>
    </body>
  );
}
